import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import _ from 'lodash'
import { XCircleIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'

// Components
import { Button } from '../Button'
import { CircleCheckIconSolid } from '../CircleCheckIconSolid'
import { EditIcon } from '../EditIcon'
import { Modal } from '../Modal'
import { PhoneNumberInput } from '../PhoneNumberInput'
import { PrinterIcon } from '../PrinterIcon'
import { Select } from '../Select'
import { TextArea } from '../TextArea'
import { TextInput } from '../TextInput'

// Store & Service
import { getBadgePrintLogs, updateAttendee } from '../../services/firestore/firestore.service'
import { KioskStoreContext } from '../../stores/KioskStore'

// TODO: possibly move location fields here if we add them to the base attendee
const BASE_FIELDS = [
  'firstName',
  'lastName',
  'companyName',
  'title',
  'first_name',
  'last_name',
  'company_name',
]

const DEFAULT = {
  attendeeid: '',
  categoryid: '',
  firstName: '',
  lastName: '',
  companyName: '',
  title: '',
  city: '',
  state: '',
  nonUSState: '',
  country: '',
  email: '',
  phone: '',
}

const PRESET_FIELDS = ['presetLocation', 'fullName']

const EditAttendeeModal = ({
  attendeeToEdit,
  deviceLocations,
  externalPopupField,
  handleCheckIn,
  registrationSyncData,
  registrationSyncEnabled,
  closeModal,
}) => {
  // Context
  const { eventId } = useContext(KioskStoreContext)

  // State
  const [activeTab, setActiveTab] = useState('attendee')
  const [categories, setCategories] = useState([])
  const [sessions, setSessions] = useState([])
  const [customFieldKeys, setCustomFieldKeys] = useState([])
  const [customFields, setCustomFields] = useState({})
  const [badgePrintLogs, setBadgePrintLogs] = useState([])
  const [attendeeNotes, setAttendeeNotes] = useState(attendeeToEdit?.customNotes || '')

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  useEffect(() => {
    if (registrationSyncData) {
      // Load attendee category options
      const { attendeeCategories, sessions: registrationSessions } = registrationSyncData
      const sortedCategories = _.sortBy(_.keys(attendeeCategories))

      const categoryOptions = _.map(sortedCategories, (c) => ({
        id: attendeeCategories[c].category_id,
        label: _.capitalize(c),
      }))
      setCategories(categoryOptions)

      // Load session options
      const sessionOptions = _.map(_.keys(registrationSessions), (k) => ({
        id: k,
        label: registrationSessions[k].name,
      }))
      setSessions(sessionOptions)

      // Add utilized custom fields to the form
      const { utilizedCustomFields } = registrationSyncData

      // Filter out custom fields that are on the base attendee object
      // and are available in the custom fields
      const additionalFields = _.filter(
        utilizedCustomFields,
        (f) =>
          !PRESET_FIELDS.includes(f) &&
          !BASE_FIELDS.includes(f) &&
          registrationSyncData.availableCustomFields[f],
      )
      // Remove the fields that have options
      const fieldsWithOptions = _.remove(
        additionalFields,
        (f) => registrationSyncData.availableCustomFields[f].options,
      )

      // Create an object with the custom fields that have options
      // We need to convert the options to an array of objects with id and label
      const customFieldsWithOptions = {}
      _.forEach(fieldsWithOptions, (f) => {
        customFieldsWithOptions[f] = _.map(
          _.values(registrationSyncData.availableCustomFields[f].options),
          (o) => ({ id: o, label: o }),
        )
      })

      setCustomFields({ withoutOptions: additionalFields, withOptions: customFieldsWithOptions })
    }
  }, [registrationSyncData])

  useEffect(() => {
    if (attendeeToEdit) {
      const attendee = { ...attendeeToEdit }

      // Extract custom data from the quick badge
      if (registrationSyncData && !_.isEmpty(categories) && !_.isEmpty(customFields)) {
        const customData = { ...attendee.customData }
        delete attendee.customData

        // Save custom field keys
        setCustomFieldKeys(_.keys(customData))

        // Set custom data fields (minus category)
        _.forEach(customData, (v, k) => {
          if (customFields.withOptions[k]) {
            const matchingOption = _.find(customFields.withOptions[k], (o) => o.id === v)
            attendee[k] = matchingOption
          } else {
            attendee[k] = v
          }
        })

        // Find matching category object for prefilling
        const matchingCategory = _.find(categories, (c) => c.id === attendee.categoryid)
        attendee.categoryid = matchingCategory

        // Find matching sessoins objects for prefilling
        const matchingSessions = _.filter(sessions, (s) => attendee.sessions.includes(s.id))
        attendee.sessions = matchingSessions
      }

      // Get badge print logs for this attendee
      const getLogs = async () => {
        const printLogs = await getBadgePrintLogs(eventId, attendeeToEdit.id)

        if (printLogs) {
          setBadgePrintLogs(_.sortBy(printLogs, 'printedAt'))
        }
      }
      getLogs()

      reset(attendee)
    }
  }, [attendeeToEdit, registrationSyncData, categories, customFields])

  /**
   * Handles printing the attendee badge with any updated data.
   * @param {object} data
   */
  const onSubmit = (data) => {
    // Extract custom data from the form data so we can transform any values as needed
    const customDataKeys = _.keys(data).filter(
      (k) => customFieldKeys.includes(k) && k !== 'sessions',
    )
    const customData = _.pick(data, customDataKeys)

    // Any values that are objects need to be converted
    const updatedCustomData = _.mapValues(customData, (c) => {
      if (_.isObject(c)) {
        return c.id
      }

      return c
    })

    // Manually handle sessions since this is a multi-select
    const sessionIds = _.map(data.sessions, 'id')
    updatedCustomData.sessions = sessionIds

    // Custom data gets nested on the attendee
    const baseData = _.pick(
      data,
      _.keys(data).filter((k) => BASE_FIELDS.includes(k)),
    )
    const attendee = {
      ...attendeeToEdit,
      ...baseData,
      customData: updatedCustomData,
    }

    // Kick off badge print
    handleCheckIn({ ...attendee })

    // Reset the form
    reset(DEFAULT)
    closeModal()
  }

  const SUB_TABS = [
    { id: 'attendee', label: 'Attendee Information' },
    { id: 'badge', label: 'Badge Print Data' },
    { id: 'popups', label: 'Pop-ups' },
  ]

  if (registrationSyncData && registrationSyncData.enableRegistrationSync) {
    SUB_TABS.splice(1, 0, { id: 'registration', label: 'Registration Options' })
  }

  const configureCheckInLocation = () => {
    const location = _.find(deviceLocations, { id: attendeeToEdit.checkInLocation })
    return location?.name || 'Unknown Location'
  }

  const configureCheckInLabel = () => {
    if (attendeeToEdit?.isCheckedIn) {
      // Manually check if date is a string, this has backwards compatibility
      return dayjs(
        _.isString(attendeeToEdit.checkedInAt)
          ? attendeeToEdit.checkedInAt
          : attendeeToEdit.checkedInAt.toDate(),
      ).format('MMM D, h:mm A')
    }

    return 'Not Checked In'
  }

  return (
    <Modal
      actions={null}
      className="sm:max-w-[1000px]"
      open
      content={
        <div className="h-[82vh]">
          <div className="mb-5 flex space-x-8">
            <div className="flex items-center space-x-2">
              <div
                className={mergeClassNames(
                  'flex h-12 w-12 items-center justify-center rounded-full bg-purple',
                )}
              >
                <EditIcon className="h-5 fill-white stroke-white sm:h-6" />
              </div>

              <div className="text-2xl font-bold">Edit Attendee</div>
            </div>
            <div className="flex">
              <div className="flex items-center justify-between space-x-4">
                {SUB_TABS.map((tab) => (
                  <button
                    className={mergeClassNames(
                      'border-b-2 pb-4 text-sm font-medium text-purple',
                      activeTab === tab.id
                        ? 'border-purple'
                        : 'border-b-transparent text-gray-600 hover:border-b-purple hover:text-purple',
                    )}
                    onClick={() => setActiveTab(tab.id)}
                    type="button"
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="h-[80%]">
            {activeTab === 'attendee' && (
              <div className="flex h-full flex-col gap-4 overflow-y-scroll">
                <div className="text-xs font-medium uppercase">Attendee Information</div>

                <div className="flex gap-4 text-center">
                  <div
                    className={mergeClassNames(
                      'basis-1/2 space-y-4',
                      (_.isEmpty(customFields) || customFields.withoutOptions?.length === 0) &&
                        'basis-full',
                    )}
                  >
                    {registrationSyncEnabled && (
                      <div className="flex flex-col sm:flex-row sm:space-x-2">
                        <TextInput
                          fullWidth
                          className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                          error={errors.attendeeid && 'This field is required'}
                          id="attendeeid"
                          inputStyles="rounded-none rounded-t-md font-nunito"
                          label="Confirmation Code"
                          labelStyles="font-nunito font-semiBold text-dark"
                          name="attendeeid"
                          placeholder="Confirmation code"
                          {...register('attendeeid', { required: true })}
                        />

                        <div className="w-full">
                          <Controller
                            name="categoryid"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                className="rounded-2xl border-gray-550 py-2.5 pl-3 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                                label="Registration Type"
                                labelClassName="font-nunito font-semiBold text-dark mb-1"
                                onChange={onChange}
                                options={categories}
                                value={value}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <div className="flex flex-col sm:flex-row sm:space-x-2">
                      <TextInput
                        fullWidth
                        className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        error={errors.firstName && 'This field is required'}
                        label="First Name"
                        labelStyles="font-nunito font-semiBold text-dark"
                        name="firstName"
                        placeholder="First name"
                        {...register('firstName', { required: true })}
                      />

                      <TextInput
                        fullWidth
                        className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        error={errors.lastName && 'This field is required'}
                        label="Last Name"
                        labelStyles="font-nunito font-semiBold text-dark"
                        name="lastName"
                        placeholder="Last name"
                        {...register('lastName', { required: true })}
                      />
                    </div>

                    <TextInput
                      className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      error={errors.companyName && 'This field is required'}
                      label="Company"
                      labelStyles="font-nunito font-semiBold text-dark"
                      name="companyName"
                      placeholder="Company"
                      {...register('companyName', { required: true })}
                    />

                    <TextInput
                      className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      error={errors.title && 'This field is required'}
                      label="Title"
                      labelStyles="font-nunito font-semiBold text-dark"
                      name="title"
                      placeholder="Title"
                      {...register('title', { required: true })}
                    />

                    {registrationSyncEnabled && (
                      <>
                        <div className="flex flex-col sm:flex-row sm:space-x-2">
                          <TextInput
                            fullWidth
                            className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                            error={errors.city && 'This field is required'}
                            label="City"
                            labelStyles="font-nunito font-semiBold text-dark"
                            name="city"
                            placeholder="City"
                            {...register('city')}
                          />
                          <TextInput
                            fullWidth
                            className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                            error={errors.state && 'This field is required'}
                            label="State"
                            labelStyles="font-nunito font-semiBold text-dark"
                            name="state"
                            placeholder="State"
                            {...register('state')}
                          />
                        </div>
                        <div className="flex flex-col sm:flex-row sm:space-x-2">
                          <TextInput
                            fullWidth
                            className="w-full rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                            error={errors.nonUSState && 'This field is required'}
                            label="Non US State"
                            labelStyles="font-nunito font-semiBold text-dark"
                            name="nonUSState"
                            placeholder="Non US state"
                            {...register('nonUSState')}
                          />
                          <TextInput
                            fullWidth
                            className="w-full rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                            error={errors.country && 'This field is required'}
                            label="Country"
                            labelStyles="font-nunito font-semiBold text-dark"
                            name="country"
                            placeholder="Country"
                            {...register('country')}
                          />
                        </div>
                      </>
                    )}

                    <TextInput
                      className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      label="Email"
                      labelStyles="font-nunito font-semiBold text-dark"
                      name="email"
                      placeholder="Email"
                      {...register('email')}
                    />

                    <PhoneNumberInput
                      control={control}
                      errors={errors}
                      hideIcon
                      label="Phone Number"
                      labelStyles="font-nunito font-semiBold text-dark"
                      name="phoneNumber"
                    />
                  </div>

                  {customFields.withoutOptions?.length > 0 && (
                    <div className="basis-1/2 space-y-4">
                      {_.map(customFields.withoutOptions, (field) => (
                        <TextInput
                          key={field}
                          className="w-full rounded-2xl border-gray-550 py-2.5  pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                          fullWidth
                          inputStyles="rounded-none rounded-t-md font-nunito"
                          name={field}
                          labelStyles="font-nunito font-semiBold text-dark"
                          label={_.capitalize(_.startCase(field))}
                          placeholder={_.capitalize(_.startCase(field))}
                          {...register(field)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            {activeTab === 'registration' && (
              <div className="flex h-full flex-col space-y-4 overflow-y-scroll">
                <Controller
                  key="sessions"
                  name="sessions"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="rounded-2xl border-gray-550 py-2.5 pl-3 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      fullWidth
                      name="sessions"
                      labelClassName="font-nunito font-semiBold text-dark mb-1"
                      onChange={onChange}
                      multiSelect
                      options={sessions}
                      label="Sessions"
                      placeholder="Select Sessions"
                      style={{ flex: true, width: '100%' }}
                      value={value}
                    />
                  )}
                />

                {customFields.withOptions ? (
                  _.map(customFields.withOptions, (options, field) => (
                    <Controller
                      key={field}
                      name={field}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          className="rounded-2xl border-gray-550 py-2.5 pl-3 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                          fullWidth
                          name={field}
                          labelClassName="font-nunito font-semiBold text-dark mb-1"
                          onChange={onChange}
                          options={options}
                          label={_.capitalize(_.startCase(field))}
                          placeholder={`Select ${_.capitalize(_.startCase(field))}`}
                          style={{ flex: true, width: '100%' }}
                          value={value}
                        />
                      )}
                    />
                  ))
                ) : (
                  <span>No registration options available.</span>
                )}
              </div>
            )}

            {activeTab === 'badge' && (
              <div className="flex h-full justify-center overflow-hidden">
                <div className="h-full w-2/3 flex-col space-y-4">
                  <span className="text-xs font-medium uppercase">Badge Print Data</span>

                  <div className="flex gap-4">
                    <div className="flex h-28 w-full flex-col justify-between rounded-xl p-4 shadow-lg">
                      <div className="flex items-center gap-2">
                        {attendeeToEdit.isCheckedIn ? (
                          <CircleCheckIconSolid className="h-4 fill-purple" />
                        ) : (
                          <XCircleIcon className="h-4 fill-red" />
                        )}
                        <span className="text-md font-bold">Check-In Time</span>
                      </div>

                      <span className="self-end text-xl font-semibold text-gray-600">
                        {configureCheckInLabel()}
                      </span>
                    </div>

                    <div className="flex h-28 w-full flex-col justify-between rounded-xl p-4 shadow-lg">
                      <span className="text-md font-bold">Reprints</span>

                      <span className="self-end text-5xl font-semibold text-gray-600">
                        {badgePrintLogs.length > 1 ? badgePrintLogs.length - 1 : 0}
                      </span>
                    </div>
                  </div>

                  {attendeeToEdit.isCheckedIn && badgePrintLogs && badgePrintLogs.length > 0 && (
                    <div className="flex h-full flex-col gap-2">
                      <span className="text-xs font-medium uppercase">Log</span>

                      <div className="flex h-[55%] flex-col gap-2 overflow-y-auto">
                        {_.map(badgePrintLogs.splice(1).reverse(), (log) => (
                          <div className="flex shrink-0 flex-col gap-2">
                            <div className="flex flex-row items-center justify-between">
                              <div className="flex flex-row items-center gap-3">
                                <div className="rounded-full bg-blue px-2.5 py-2">
                                  <PrinterIcon className="h-6 fill-white" />
                                </div>

                                <span className="text-md font-normal text-gray-dark">
                                  Badge Reprinted
                                </span>
                              </div>

                              <span className="text-gray-600">
                                {/* Manually check if date is a string, this has backwards compatibility */}
                                {dayjs(
                                  _.isString(log.printedAt)
                                    ? log.printedAt
                                    : log.printedAt.toDate(),
                                ).format('MMM D, h:mm A')}
                              </span>
                            </div>

                            <div className="ml-[18px] h-5 w-[2px] bg-gray-300" />
                          </div>
                        ))}

                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-row items-center gap-3">
                            <div className="rounded-full bg-purple px-2.5 py-2">
                              <PrinterIcon className="h-6 fill-white" />
                            </div>

                            <span className="text-md font-normal text-gray-dark">
                              Badge Printed
                            </span>
                          </div>

                          <span className="text-gray-600">
                            {/* Manually check if date is a string, this has backwards compatibility */}
                            {dayjs(
                              _.isString(badgePrintLogs[0].printedAt)
                                ? badgePrintLogs[0].printedAt
                                : badgePrintLogs[0].printedAt.toDate(),
                            ).format('MMM D, h:mm A')}
                          </span>
                        </div>

                        <div className="ml-[18px] h-5 w-[2px] shrink-0 bg-gray-300" />

                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-row items-center gap-3">
                            <CircleCheckIconSolid className="h-10 fill-green" />

                            <span className="text-md font-normal text-gray-dark">
                              Checked In at{' '}
                              <span className="font-medium text-gray-dark">
                                {configureCheckInLocation()}
                              </span>
                            </span>
                          </div>

                          <span className="text-gray-600">
                            {/* Manually check if date is a string, this has backwards compatibility */}
                            {dayjs(
                              _.isString(attendeeToEdit.checkedInAt)
                                ? attendeeToEdit.checkedInAt
                                : attendeeToEdit.checkedInAt.toDate(),
                            ).format('MMM D, h:mm A')}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {activeTab === 'popups' && (
              <div className="flex">
                <div className="flex basis-1/2 flex-col space-y-4">
                  {externalPopupField && (
                    <div className="flex flex-col space-y-1">
                      <div className="text-xs font-medium uppercase">Registration Notes</div>
                      <div className="ml-4 text-xs">
                        {attendeeToEdit.customData[externalPopupField] || 'No external notes.'}
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col space-y-2">
                    <div className="text-xs font-medium uppercase">Attendee Notes</div>
                    <TextArea
                      className="rounded-lg border-gray-400 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      fullWidth
                      inputStyles="rounded-none"
                      onChange={(e) => setAttendeeNotes(e.target.value)}
                      placeholder="Add any custom notes for this attendee"
                      resize="resize-none"
                      rows={6}
                      value={attendeeNotes}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            )}
          </div>

          <div className="mt-4 flex shrink-0 items-center justify-between gap-4">
            <Button
              background="bg-white"
              className={mergeClassNames(
                'basis-1/3 text-gray-700',
                activeTab !== 'popups' && 'basis-1/2',
              )}
              label="Cancel"
              onClick={closeModal}
            />

            <Button
              className={mergeClassNames(
                'basis-1/3 bg-teal text-gray-700 hover:bg-teal-light',
                activeTab !== 'popups' && 'basis-1/2',
              )}
              label="Print Badge"
              onClick={handleSubmit(onSubmit)}
            />

            {activeTab === 'popups' && (
              <Button
                className="basis-1/3 bg-purple text-white hover:bg-purple-light"
                label="Save Changes"
                onClick={() => {
                  // Save changes
                  updateAttendee(eventId, {
                    ...attendeeToEdit,
                    customNotes: attendeeNotes,
                  })
                  closeModal()
                }}
              />
            )}
          </div>
        </div>
      }
    />
  )
}

EditAttendeeModal.defaultProps = {
  externalPopupField: null,
}

EditAttendeeModal.propTypes = {
  attendeeToEdit: PropTypes.object.isRequired,
  editAttendeeModalOpen: PropTypes.bool.isRequired,
  deviceLocations: PropTypes.array.isRequired,
  externalPopupField: PropTypes.string,
  handleCheckIn: PropTypes.func.isRequired,
  registrationSyncData: PropTypes.object.isRequired,
  registrationSyncEnabled: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default EditAttendeeModal
